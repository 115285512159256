import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';

import Logo from '../assets/images/jdr-logo.svg';

export default function ContactForm() {
  return (
    <nav className="relative flex flex-row justify-center md:justify-between items-center pt-12 select-none">
      <div className="hidden md:flex w-20 ml-12" />
      <ul className="menu flex flex-row items-center justify-center flex-grow">
        {/* <li className="hidden md:block menu-item mx-6 px-4 py-2 uppercase font-medium tracking-widest">
          Home
        </li> */}
        {/* <li className="hidden md:block menu-item mx-6 px-4 py-2 uppercase font-medium tracking-widest">
              Gallery
            </li> */}
        <li className="mx-6 cursor-pointer">
          <div className="">
            <Link to="/">
              <Logo className="logo" />
            </Link>
          </div>
        </li>
        {/* <li className="hidden md:block menu-item mx-6 px-4 py-2 uppercase font-medium tracking-widest">
              About
            </li> */}
        {/* <li className="hidden md:block menu-item mx-6 px-4 py-2 uppercase font-medium tracking-widest">
          <Link to="#contact">Contact</Link>
        </li> */}

      </ul>
      <div className="hidden mr-12 md:flex flex-row">
                    {/* Instagram */}
                    <div className="pr-4">
                      <a
                        href="https://www.instagram.com/just.done.right/"
                        target="_blank"
                        rel="noreferrer">
                        <svg
                          className="social-icon w-5 h-5 text-red-400 hover:text-red-500"
                          xmlns="http://www.w3.org/2000/svg"
                          width="39.434"
                          height="39.434"
                          viewBox="0 0 39.434 39.434">
                          <path
                            id="Path_236"
                            data-name="Path 236"
                            d="M19.717,3.505a60.456,60.456,0,0,1,7.887.219,10.161,10.161,0,0,1,3.724.657,7.694,7.694,0,0,1,3.724,3.724,10.161,10.161,0,0,1,.657,3.724c0,1.972.219,2.629.219,7.887a60.456,60.456,0,0,1-.219,7.887,10.161,10.161,0,0,1-.657,3.724,7.694,7.694,0,0,1-3.724,3.724,10.161,10.161,0,0,1-3.724.657c-1.972,0-2.629.219-7.887.219a60.456,60.456,0,0,1-7.887-.219,10.161,10.161,0,0,1-3.724-.657,7.694,7.694,0,0,1-3.724-3.724A10.161,10.161,0,0,1,3.724,27.6c0-1.972-.219-2.629-.219-7.887a60.456,60.456,0,0,1,.219-7.887,10.161,10.161,0,0,1,.657-3.724A7.868,7.868,0,0,1,5.915,5.915,3.7,3.7,0,0,1,8.106,4.382a10.161,10.161,0,0,1,3.724-.657,60.456,60.456,0,0,1,7.887-.219m0-3.505a64.731,64.731,0,0,0-8.106.219,13.525,13.525,0,0,0-4.82.876A8.576,8.576,0,0,0,3.286,3.286,8.576,8.576,0,0,0,1.1,6.791a9.982,9.982,0,0,0-.876,4.82A64.731,64.731,0,0,0,0,19.717a64.731,64.731,0,0,0,.219,8.106,13.525,13.525,0,0,0,.876,4.82,8.576,8.576,0,0,0,2.191,3.505,8.576,8.576,0,0,0,3.505,2.191,13.525,13.525,0,0,0,4.82.876,64.732,64.732,0,0,0,8.106.219,64.731,64.731,0,0,0,8.106-.219,13.525,13.525,0,0,0,4.82-.876,9.191,9.191,0,0,0,5.7-5.7,13.525,13.525,0,0,0,.876-4.82c0-2.191.219-2.848.219-8.106a64.732,64.732,0,0,0-.219-8.106,13.525,13.525,0,0,0-.876-4.82,8.576,8.576,0,0,0-2.191-3.505A8.576,8.576,0,0,0,32.642,1.1a13.525,13.525,0,0,0-4.82-.876A64.731,64.731,0,0,0,19.717,0m0,9.639A9.915,9.915,0,0,0,9.639,19.717,10.078,10.078,0,1,0,19.717,9.639m0,16.65a6.455,6.455,0,0,1-6.572-6.572,6.455,6.455,0,0,1,6.572-6.572,6.455,6.455,0,0,1,6.572,6.572,6.455,6.455,0,0,1-6.572,6.572m10.516-19.5a2.41,2.41,0,1,0,2.41,2.41,2.431,2.431,0,0,0-2.41-2.41"
                            fill="currentColor"
                            fillRule="evenodd"
                          />
                        </svg>
                      </a>
                    </div>
                    {/* Facebook */}
                    <div className="px-4">
                      <a
                        href="https://www.facebook.com/teamJDR"
                        target="_blank"
                        rel="noreferrer">
                        <svg
                          className="social-icon w-5 h-5 text-blue-400 hover:text-blue-500"
                          xmlns="http://www.w3.org/2000/svg"
                          width="20.593"
                          height="39.434"
                          viewBox="0 0 20.593 39.434">
                          <path
                            id="Path_234"
                            data-name="Path 234"
                            d="M93.364,39.434V21.469H99.5l.876-7.01h-7.01V10.078c0-1.972.657-3.505,3.505-3.505h3.724V.219C99.717.219,97.526,0,95.116,0c-5.258,0-8.982,3.286-8.982,9.2v5.258H80v7.01h6.134V39.434Z"
                            transform="translate(-80)"
                            fill="currentColor"
                            fillRule="evenodd"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
    </nav>
  );
}
