import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';

const FormStyles = styled.div`
/* w-3/4 mx-auto md:mx-0 mb-20 md:mb-0 */

    input,
    textarea,
    select {
      border-color: #252525;
      font-size: 

      &:focus {
        border-color: #616161;
      }
    }
  }`;

export default function ContactForm() {
  return (
    <FormStyles className="w-3/4 md:w-full mx-auto md:mx-0 mb-20 md:mb-0">
      <form
        method="POST"
        netlify-honeypot="bot-field"
        data-netlify="true"
        name="contact"
        className="grid grid-cols-1 gap-y-3 sm:grid-cols-2 sm:gap-x-3">
        {/* First Name */}
        <div>
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />

          <label htmlFor="first_name" className="sr-only">
            First name
          </label>
          <div className="mt-1">
            <input
              type="text"
              placeholder="First Name"
              name="first_name"
              id="first_name"
              autoComplete="given-name"
              className="placeholder-gray-400 py-3 px-4 block w-full text-gray-200 bg-dark-gray shadow-sm focus:ring-gray-200 focus:border-gray-200 border-gray-900 rounded-md"
            />
          </div>
        </div>

        {/* Last Name */}
        <div>
          <label htmlFor="last_name" className="sr-only">
            Last name
          </label>
          <div className="mt-1">
            <input
              type="text"
              placeholder="Last Name"
              name="last_name"
              id="last_name"
              autoComplete="family-name"
              className="placeholder-gray-400 py-3 px-4 block w-full text-gray-200 bg-dark-gray shadow-sm focus:ring-gray-200 focus:border-gray-200 border-gray-900 rounded-md"
            />
          </div>
        </div>

        {/* Email Address */}
        <div className="sm:col-span-2">
          <label htmlFor="email" className="sr-only">
            Email
          </label>
          <div className="mt-1">
            <input
              id="email"
              placeholder="Email"
              name="email"
              type="email"
              autoComplete="email"
              className="placeholder-gray-400 py-3 px-4 block w-full text-gray-200 bg-dark-gray shadow-sm focus:ring-gray-200 focus:border-gray-200 border-gray-900 rounded-md"
            />
          </div>
        </div>

        {/* Phone Number */}
        <div className="sm:col-span-2">
          <label htmlFor="phone_number" className="sr-only">
            Phone Number
          </label>
          <div className="mt-1">
            <input
              type="text"
              placeholder="+1 (555) 987-6543"
              name="phone_number"
              id="phone_number"
              autoComplete="tel"
              className="placeholder-gray-400 py-3 px-4 block w-full text-gray-200 bg-dark-gray shadow-sm focus:ring-gray-200 focus:border-gray-200 border-gray-900 rounded-md"
            />
          </div>
        </div>

        {/* Type of Project */}
        <div>
          <div className="mt-1 relative rounded-md shadow-sm">
            <select
              aria-label="Project Type"
              placeholder="Project Type"
              id="project_type"
              name="project_type"
              className="placeholder-gray-400 py-3 px-4 block w-full text-gray-200 bg-dark-gray shadow-sm focus:ring-gray-200 focus:border-gray-200 border-gray-900 rounded-md">
              <option value="Excavation Services">Excavation Services</option>
              <option value="Custom Construction">Custom Construction</option>
              <option value="Property Reclamation">Property Reclamation</option>
              <option value="Other">Other</option>
              <option value="Select a Service" hidden disabled defaultValue>
                Select a Service
              </option>
            </select>
          </div>
        </div>
        {/* Zip Code */}
        <div>
          <div className="mt-1 relative rounded-md shadow-sm">
            <input
              aria-label="Zip Code"
              placeholder="Zip Code"
              id="zip_code"
              type="text"
              pattern="[0-9]{5}"
              name="zip_code"
              className="placeholder-gray-400 py-3 px-4 block w-full text-gray-200 bg-dark-gray shadow-sm focus:ring-gray-200 focus:border-gray-200 border-gray-900 rounded-md"
              required
            />
          </div>
        </div>

        {/* Preferred Start Date (0-4w, 1-3m, 3-6m, 6+) */}
        {/* Description of request */}
        <div className="sm:col-span-2">
          <label htmlFor="message" className="sr-only">
            Message
          </label>
          <div className="mt-1">
            <textarea
              id="message"
              placeholder="Description of your request"
              name="message"
              rows="4"
              className="placeholder-gray-400 py-3 px-4 block w-full text-gray-200 bg-dark-gray shadow-sm focus:ring-gray-200 focus:border-gray-200 border-gray-900 rounded-md"
            />
          </div>
        </div>

        {/* Button */}

        <div className="sm:col-span-2">
          <button
            type="submit"
            className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-gray-200 bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200">
            Let's talk
          </button>
        </div>
      </form>
    </FormStyles>
  );
}
