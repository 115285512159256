import React from 'react';

function ListCard({ title1, title2, feat1, feat2, feat3, feat4, feat5 }) {
  return (
    <div className="card flex flex-col items-center p-8 text-center">
      <div>
        <h5 className="font-title text-3xl leading-none">
          {title1}
          <span className="text-gray-400 text-2xl block">{title2}</span>
        </h5>
      </div>
      <div className="pt-6 text-left">
        <ul>
          <li className="uppercase pb-2 text-sm font-medium tracking-widest">
            - {feat1}
          </li>
          <li className="uppercase pb-2 text-sm font-medium tracking-widest">
            - {feat2}
          </li>
          <li className="uppercase pb-2 text-sm font-medium tracking-widest">
            - {feat3}
          </li>
          <li className="uppercase pb-2 text-sm font-medium tracking-widest">
            - {feat4}
          </li>
          <li className="uppercase pb-2 text-sm font-medium tracking-widest">
            - {feat5}
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ListCard;
