import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import styled from '@emotion/styled';
import Nav from '../components/Navigation';
import ListCard from '../components/ListCard';
import IG from '../components/InstaFeed';

import Logo from '../assets/images/jdr-logo.svg';

import ContentWrap from '../components/ContentWrap';
import SEO from '../components/SEOComponent';
import ContactForm from '../components/ContactForm';
import PlaceHolder from '../assets/images/feed-1-min.jpg';
import BG from '../assets/images/bg.jpg';
import BrushBG from '../assets/images/brush-min.svg';
import TreelineBG from '../assets/images/mas-treeline-min.jpg';
import TreelineBG2 from '../assets/images/treeline-min.png';
import LogoMark from '../assets/images/jdr-logo-mark.svg';
import ExcavationLogo from '../assets/images/jdr-excavation-logo.svg';

const WoodBG = styled.div`
  background-image: url(${BG});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;
const Treeline = styled.div`
  background-image: url(${TreelineBG});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 326px;
  background-color: #dbdbdb;
`;
const Footer = styled.footer`
  background-image: url(${TreelineBG2});
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  height: 500px;
`;

function IndexPage({ data }) {
  return (
    <ContentWrap>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />

      {/* Navigation */}
      <WoodBG className="h-screen h-full flex flex-col justify-center">
        <Nav />


        {/* Hero */}
        <div className="flex-grow" />


        <div className="flex flex-grow md:flex-grow-0 justify-center items-center w-full mx-auto">
          <div className="flex flex-col ml-4 sm:ml-16 mb-10 md:flex-row h-4/5">
            <Link
              to="#excavation"
              className="flex flex-row items-center max-w-3/4 md:w-auto md:pr-16 transition duration-300 ease-in-out transform hover:scale-110 hover:text-white mb-8">
              <ExcavationLogo className="w-24 sm:w-36 mb-4 pr-2" />
              <div className="px-2">
                <h5 className="font-title text-xl sm:text-3xl md:text-2xl md:leading-none leading-none">
                  Excavation
                  <span className="text-gray-400 text-2xl md:text-xl block">
                    Services{' '}
                    <div className="inline-block transform -translate-y-0.5">
                      >
                    </div>
                  </span>
                </h5>
              </div>
            </Link>
            <Link
              to="#custom-homes"
              className="flex flex-row items-center max-w-3/4 md:w-auto md:pr-16 transition duration-300 ease-in-out transform hover:scale-110 hover:text-white mb-8">
              <LogoMark className="w-24 sm:w-36 pr-2" />
              <div className="px-2">
                <h5 className="font-title text-xl sm:text-3xl md:text-2xl md:leading-none leading-none">
                  Custom Home
                  <span className="text-gray-400 text-2xl md:text-xl block">
                    Builds{' '}
                    <div className="inline-block transform -translate-y-0.5">
                      >
                    </div>
                  </span>
                </h5>
              </div>
            </Link>
          </div>
        </div>
        <div className="hero flex justify-center mb-20">
          <div className="flex flex-col text-center">
            <h1 className="font-sans text-xl md:text-2xl tracking-widest uppercase">
              How you do anything is
              <span className="font-title text-3xl md:text-5xl tracking-wide leading-tight block">
                How you do everything
              </span>
            </h1>
          </div>
        </div>

        <div className="flex justify-center flex-row mb-10">
          <div className="grid grid-cols-12 w-3/4">
            <div className="col-span-10 border-t border-white" />
            <div className="col-span-2 border-t-4 border-white transform -translate-y-0.5" />
          </div>
        </div>

        <div className="md:flex-grow" />
      </WoodBG>

      {/* Specialties */}
      <div className="specialties w-full" id="specialties">
        <div className="mx-auto container max-w-screen-lg pt-20 px-12">
          <div className="flex flex-col">
            <div className="text-center pb-16">
              <h3 className="font-title text-2xl md:text-3xl">
                We can handle anything you throw at us.
              </h3>
              <span className="font-sans text-xl md:text-2xl tracking-widest uppercase">
                Our specialties include:
              </span>
            </div>

            <div className="grid grid-row-3 lg:grid-cols-3 gap-4">
              <ListCard
                title1="Excavation"
                title2="Services:"
                feat1="Water Features"
                feat2="Hardscapes"
                feat3="Retaining Walls"
                feat4="General Residential"
                feat5="Demolition"
              />

              <ListCard
                title1="Custom"
                title2="Construction:"
                feat1="New Builds"
                feat2="Foundation to Finish"
                feat3="High-End Finishing"
                feat4="New Residential"
                feat5="Commercial Projects"
              />
              <ListCard
                title1="Property"
                title2="Reclamation:"
                feat1="Road Building"
                feat2="Land Clearing"
                feat3="Stump Removal"
                feat4="Grading & Leveling"
                feat5="Water & Drainage"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Excavation Services */}
      <div
        id="excavation"
        className="container max-w-screen-lg pt-20 px-12 mx-auto md:flex flex-row items-center md:pl-0">
        {/* Image */}
        <div className="w-full">
          <Img
            className="rounded-sm"
            fluid={{
              ...data.excavation.childImageSharp.fluid,
            }}
            alt={data.excavation.name}
          />
        </div>

        {/* Content */}
        <div className="bg-dark-gray w-11/12 mx-auto p-6 lg:p-14 transform -translate-y-8 md:translate-y-0">
          <div className="grid max-w-xs lg:max-w-2xl mx-auto grid-cols-5 items-center justify-items-center">
            {/* Logo */}
            <ExcavationLogo className="max-w-full mb-4 pr-2 col-span-2 lg:row-start-3" />
            {/* Title */}
            <div className="col-span-3 lg:col-span-5 lg:justify-self-start">
              <h5 className="font-title text-xl lg:text-4xl lg:leading-none leading-none lg:mb-4">
                Excavation
                <span className="text-gray-400 text-lg lg:text-2xl block">
                  Services
                </span>
              </h5>
            </div>

            {/* Line */}
            <div className="grid grid-cols-12 w-full mt-4 mb-8 col-span-5 lg:hidden">
              <div className="col-span-10 border-t border-white" />
              <div className="col-span-2 border-t-4 border-white transform -translate-y-0.5" />
            </div>

            {/* Text */}
            <div className="col-span-5 lg:mb-4">
              <p>
                We offer a number of excavation services to take your project to
                the next level.
              </p>
            </div>

            {/* Bullets */}
            <div className="pt-6 text-left col-span-5 lg:col-span-3">
              <ul className="uppercase text-xs font-medium tracking-widest">
                <li className="pb-2">- Water Features</li>
                <li className="pb-2">- Hardscapes</li>
                <li className="pb-2">- Retaining Walls</li>
                <li className="pb-2">- General Residential</li>
                <li className="pb-2">- Demolition</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Custom Home Builds */}
      <div
        id="custom-homes"
        className="container max-w-screen-lg pt-20 px-12 mx-auto md:flex flex-row items-center md:pl-0">
        {/* Image */}
        <div className="w-full md:order-1">
          <Img
            className="rounded-sm"
            fluid={{
              ...data.customHome.childImageSharp.fluid,
            }}
            alt={data.customHome.name}
          />
        </div>

        {/* Content */}
        <div className="bg-dark-gray w-11/12 mx-auto p-6 lg:p-14 transform -translate-y-20 md:translate-y-0">
          <div className="grid max-w-xs lg:max-w-2xl mx-auto grid-cols-5 items-center justify-items-center">
            {/* Logo */}
            <LogoMark className="max-w-full mb-4 lg:mb-0 pr-2 col-span-2 lg:row-start-3" />
            {/* Title */}
            <div className="col-span-3 lg:col-span-5 lg:justify-self-end">
              <h5 className="font-title text-xl lg:text-4xl lg:leading-none leading-none lg:mb-4 lg:text-right">
                Custom Home
                <span className="text-gray-400 text-lg lg:text-2xl block">
                  Building
                </span>
              </h5>
            </div>

            {/* Line */}
            <div className="grid grid-cols-12 w-full mt-4 mb-8 col-span-5 lg:hidden">
              <div className="col-span-10 border-t border-white" />
              <div className="col-span-2 border-t-4 border-white transform -translate-y-0.5" />
            </div>

            {/* Text */}
            <div className="col-span-5 lg:mb-4 lg:text-right lg:justify-self-end">
              <p>
                High-end homes built right. You can rest assured that we don’t
                cut corners.
              </p>
            </div>

            {/* Bullets */}
            <div className="pt-6 text-left col-span-5 lg:col-span-3">
              <ul className="uppercase text-xs font-medium tracking-widest">
                <li className="pb-2">- New Builds</li>
                <li className="pb-2">- Foundation-to-finish</li>
                <li className="pb-2">- High-end finishing</li>
                <li className="pb-2">- New Residential</li>
                <li className="pb-2">- Commercial Projects</li>
              </ul>
            </div>
          </div>
        </div>
      </div>



{/* About Us  */}
<div className="container max-w-screen-lg pt-20 lg:pt-40 px-12 mx-auto mb-20">

{/* Title & Line */}


    <div className="flex flex-col lg:flex-row">
      <div className="lg:mr-20 mb-10 lg:mb-0">
      <h5 className="font-title text-4xl sm:text-5xl text-center md:text-left sm:leading-3 leading-3">
        With over 20
        <span className="text-gray-400 text-2xl sm:text-3xl sm:leading-none leading-none">
        <br className=""/>Years of Experience
        </span>
      </h5>
      </div>

      {/* Line */}
      <div className="flex-grow grid grid-cols-12 col-span-5 transform -translate-y-2">
        <div className="col-span-10 border-b border-white" />
        <div className="col-span-2 border-b-4 border-white transform translate-y-0.5" />
      </div>

    </div>




    {/* Content */}
    <div className="mt-8 md:grid md:grid-cols-2 md:gap-8">
      {/* Col 1 */}
      <div className="mt-8 md:mt-0">
        <div className="prose mx-auto">
          <p className="text-gray-300 prose-xl">...in the construction industry, JDR’s owner, Justin Tedrow, has honed his skills and builds as a master craftsman. </p>
          <p className="text-gray-500">He and his team firmly believe in giving not only their clients a quality product but they also live by a code of honor to walk in integrity in every job and on every project.  Than’t not to say there won’t be hiccups or challenges but they know that any job worth doing is one worth doing right and they strive to make sure to see that every customer’s needs are met and their projects are completed with integrity and excellence. </p>
        </div>
      </div>

      {/* Col 2 */}
      <div className="mt-5 md:mt-0">
        <div className="prose text-gray-500 mx-auto">
          <p>As a young man, Justin attended Heavy Equipment Operators school and was trained and certified for excavation projects small and large. He worked for one of valley’s premier companies in this field for a season and loved it!</p>
          <p>He also got a taste of general contracting and found he loved to see the process of seeing and being responsible for a home being built from the ground up.  He was mentored by working for some of the most amazing builders in the valley who shared their expertise with him.  In 2002, Justin started his own contracting company and the rest is history.</p>
        </div>
      </div>
    </div>
</div>











      {/* Content
        <div className="bg-dark-gray w-11/12 mx-auto p-6 transform -translate-y-8">
          Logo
          <div className="flex flex-row justify-center items-center mb-4 mx-auto">
            <ExcavationLogo className="w-28 mb-4 pr-2" />
            <div className="px-2">
              <h5 className="font-title text-xl md:text-2xl md:leading-none leading-none">
                Excavation
                <span className="text-gray-400 text-lg md:text-xl block">
                  Services
                </span>
              </h5>
            </div>
          </div>

          Line
          <div className="grid grid-cols-12 w-full mb-8">
            <div className="col-span-10 border-t border-white" />
            <div className="col-span-2 border-t-4 border-white transform -translate-y-0.5" />
          </div>

          Text & Bullets
          <div className="flex flex-col items-center text-left">
            <div>
              <p>
                We offer a number of excavation services to take your project to
                the next level.
              </p>
            </div>
            <div className="pt-6 text-left">
              <ul>
                <li className="uppercase pb-2 text-sm font-medium tracking-widest">
                  - Water Features
                </li>
                <li className="uppercase pb-2 text-sm font-medium tracking-widest">
                  - Hardscapes
                </li>
                <li className="uppercase pb-2 text-sm font-medium tracking-widest">
                  - Retaining Walls
                </li>
                <li className="uppercase pb-2 text-sm font-medium tracking-widest">
                  - General Residential
                </li>
                <li className="uppercase pb-2 text-sm font-medium tracking-widest">
                  - Demolition
                </li>
              </ul>
            </div>
          </div>
        </div> */}

      {/* Review */}
      <div className="hidden lg:block container max-w-screen-lg mx-auto py-32">
        <div>
          <div className="text-center pb-24">
            <h1 className="font-title text-4xl leading-none">
            Doing the right thing isn't <br />always easy, but it's always right!
            </h1>
            <span className="text-xl text-gray-300 tracking-widest uppercase">
              That’s Our Just-Done-Right Guarantee.
            </span>
          </div>

          <div className="mx-10">
            <div className="relative flex items-center justify-center">
              <hr className="w-full hr-dark" />
              <span className="quote font-title absolute text-dark-300 bg-dark-500 px-6 tracking-widest">
                {' '}
                “{' '}
              </span>
            </div>
            <p className="tracking-widest text-xl text-center leading-loose py-12 relative z-10">
              These guys work hard and do excellent work. They know what they
              are doing. Highly recommend.
            </p>
            <div className="relative flex items-center justify-center">
              <hr className="w-full hr-dark" />
              <a
                href="https://www.facebook.com/denny.cline/posts/10221434991042676"
                target="_blank"
                rel="noreferrer"
                className="absolute hover:text-gray-300 opacity-75 bg-dark-500 text-lg uppercase font-bold px-6 tracking-widest">
                - Denny Cline
              </a>
            </div>
          </div>
        </div>
      </div>

{/* IG Feed  */}
<IG/>
      {/* <!-- GALLERY --> */}
      {/* <div className="gallery w-full py-32 bg-dark-500">
        <div className="bg-dark-400 mx-8 md:mx-0">
          <Treeline className="banner text-center flex justify-center">
            <div className="w-full md:w-1/2 flex flex-col justify-center items-center">
              <h1 className="text-xl md:text-3xl lg:text-5xl tracking-widest p-6 uppercase">
                Our work speaks for itself.
              </h1>
              <div className="hidden md:block w-full lg:w-3/4 tracking-wider font-bold leading-loose uppercase">
                <span>
                  The following squares represent gallery images that will
                  automatically populate from your instagram feed.
                </span>
              </div>
            </div>
          </Treeline>

          <div className="grid grid-cols-4 gap-0 -mx-8 md:mx-0">
            <div>
              <img src={PlaceHolder} alt="" loading="lazy" />
            </div>
            <div>
              <img src={PlaceHolder} alt="" loading="lazy" />
            </div>
            <div>
              <img src={PlaceHolder} alt="" loading="lazy" />
            </div>
            <div>
              <img src={PlaceHolder} alt="" loading="lazy" />
            </div>
          </div>
          {/* <!-- SHOW ONLY ON MOBILE --> }
          <div className="block md:hidden px-12 py-8 text-center">
            <span className="text-xs md:text-base tracking-wider leading-loose uppercase">
              These Images will automatically populate from an instagram feed.
            </span>
          </div>
        </div>
      </div> */}

      {/* <!-- SCHEDULE --> */}
      <WoodBG className="py-32 px-6">
        <div className="container mx-auto">
          <div className="grid grid-row-2 md:grid-cols-2 gap-4 h-full">
            <div className="flex flex-col justify-center items-center text-center md:text-left">
              <div className="w-full md:w-3/4">
                <div className="">
                  <h1 className="font-title text-3xl lg:text-5xl leading-none">
                    Schedule
                  </h1>
                  <h1 className="font-title text-gray-400 text-xl md:text-2xl">
                    A free estimate today
                  </h1>
                </div>
                <div className="py-6">
                  <ul>
                    <li className="uppercase pb-2 text-sm font-medium tracking-widest">
                      - 100% Free Estimate
                    </li>
                    <li className="uppercase pb-2 text-sm font-medium tracking-widest">
                      - Accurate & Honest
                    </li>
                    <li className="uppercase pb-2 text-sm font-medium tracking-widest">
                      - Service Guarantee
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* <!-- FORM --> */}
            <div className="flex flex-col justify-center items-center w-full md:w-3/4 mx-auto">
              <ContactForm />
            </div>
          </div>
        </div>
      </WoodBG>

      {/* <!-- BANNER --> */}
      <div className="py-8 md:py-32 px-6">
        <div className="grid grid-rows-2 md:grid-rows-1 md:grid-cols-2 gap-4 h-full">
          <div className="flex flex-col items-center md:items-end justify-center">
            <h4 className="font-title text-3xl md:text-3xl lg:text-4xl xl:text-5xl block text-center md:text-left leading-none">
              Ready for the home
              <span className="font-title text-gray-400 text-lg md:text-sm lg:text-xl xl:text-2xl block">
                you've always dreamed of?
              </span>
            </h4>
          </div>
          <div className="flex flex-col items-center justify-center py-6 text-left">
            <ul>
              <li className="uppercase pb-2 text-sm font-medium tracking-widest">
                - Custom Home Design
              </li>
              <li className="uppercase pb-2 text-sm font-medium tracking-widest">
                - Foundation to Finish
              </li>
              <li className="uppercase pb-2 text-sm font-medium tracking-widest">
                - New Construction
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Contact */}
      <div className="contact py-32 px-6">
        <div className="container mx-auto">
          <div className="grid grid-row-2 md:grid-cols-2 gap-4 h-full">
            {/* <!-- CONTACT INFO --> */}
            <div className="flex flex-col justify-center items-center">
              <div className="grid grid-cols-2 md:grid-cols-1 gap-6 md:gap-0">
                <div className="order-2 md:order-1">
                  <div className="md:pb-12">
                    <p className="pb-2 text-sm md:text-base">
                      info@teamjdr.com
                    </p>
                    <p className="pb-2 text-sm md:text-base">(541) 936-9909</p>
                  </div>
                  <div className="pb-12">
                    <p className="pb-2 text-sm md:text-base">
                      Monday - Saturday
                    </p>
                    <p className="pb-2 text-sm md:text-base">
                      9:00 am - 5:00 pm
                    </p>
                  </div>
                </div>
                <div className="pb-12 order-1">
                  <p className="text-sm md:text-xl font-bold pb-6">
                    Follow Us.
                  </p>
                  <div className="flex flex-row">
                    {/* Instagram */}
                    <div className="pr-4">
                      <a
                        href="https://www.instagram.com/just.done.right/"
                        target="_blank"
                        rel="noreferrer">
                        <svg
                          className="social-icon w-5 h-5 md:w-8 md:h-8"
                          xmlns="http://www.w3.org/2000/svg"
                          width="39.434"
                          height="39.434"
                          viewBox="0 0 39.434 39.434">
                          <path
                            id="Path_236"
                            data-name="Path 236"
                            d="M19.717,3.505a60.456,60.456,0,0,1,7.887.219,10.161,10.161,0,0,1,3.724.657,7.694,7.694,0,0,1,3.724,3.724,10.161,10.161,0,0,1,.657,3.724c0,1.972.219,2.629.219,7.887a60.456,60.456,0,0,1-.219,7.887,10.161,10.161,0,0,1-.657,3.724,7.694,7.694,0,0,1-3.724,3.724,10.161,10.161,0,0,1-3.724.657c-1.972,0-2.629.219-7.887.219a60.456,60.456,0,0,1-7.887-.219,10.161,10.161,0,0,1-3.724-.657,7.694,7.694,0,0,1-3.724-3.724A10.161,10.161,0,0,1,3.724,27.6c0-1.972-.219-2.629-.219-7.887a60.456,60.456,0,0,1,.219-7.887,10.161,10.161,0,0,1,.657-3.724A7.868,7.868,0,0,1,5.915,5.915,3.7,3.7,0,0,1,8.106,4.382a10.161,10.161,0,0,1,3.724-.657,60.456,60.456,0,0,1,7.887-.219m0-3.505a64.731,64.731,0,0,0-8.106.219,13.525,13.525,0,0,0-4.82.876A8.576,8.576,0,0,0,3.286,3.286,8.576,8.576,0,0,0,1.1,6.791a9.982,9.982,0,0,0-.876,4.82A64.731,64.731,0,0,0,0,19.717a64.731,64.731,0,0,0,.219,8.106,13.525,13.525,0,0,0,.876,4.82,8.576,8.576,0,0,0,2.191,3.505,8.576,8.576,0,0,0,3.505,2.191,13.525,13.525,0,0,0,4.82.876,64.732,64.732,0,0,0,8.106.219,64.731,64.731,0,0,0,8.106-.219,13.525,13.525,0,0,0,4.82-.876,9.191,9.191,0,0,0,5.7-5.7,13.525,13.525,0,0,0,.876-4.82c0-2.191.219-2.848.219-8.106a64.732,64.732,0,0,0-.219-8.106,13.525,13.525,0,0,0-.876-4.82,8.576,8.576,0,0,0-2.191-3.505A8.576,8.576,0,0,0,32.642,1.1a13.525,13.525,0,0,0-4.82-.876A64.731,64.731,0,0,0,19.717,0m0,9.639A9.915,9.915,0,0,0,9.639,19.717,10.078,10.078,0,1,0,19.717,9.639m0,16.65a6.455,6.455,0,0,1-6.572-6.572,6.455,6.455,0,0,1,6.572-6.572,6.455,6.455,0,0,1,6.572,6.572,6.455,6.455,0,0,1-6.572,6.572m10.516-19.5a2.41,2.41,0,1,0,2.41,2.41,2.431,2.431,0,0,0-2.41-2.41"
                            fill="#d0d0d0"
                            fillRule="evenodd"
                          />
                        </svg>
                      </a>
                    </div>
                    {/* Facebook */}
                    <div className="px-4">
                      <a
                        href="https://www.facebook.com/teamJDR"
                        target="_blank"
                        rel="noreferrer">
                        <svg
                          className="social-icon w-5 h-5 md:w-8 md:h-8"
                          xmlns="http://www.w3.org/2000/svg"
                          width="20.593"
                          height="39.434"
                          viewBox="0 0 20.593 39.434">
                          <path
                            id="Path_234"
                            data-name="Path 234"
                            d="M93.364,39.434V21.469H99.5l.876-7.01h-7.01V10.078c0-1.972.657-3.505,3.505-3.505h3.724V.219C99.717.219,97.526,0,95.116,0c-5.258,0-8.982,3.286-8.982,9.2v5.258H80v7.01h6.134V39.434Z"
                            transform="translate(-80)"
                            fill="#d0d0d0"
                            fillRule="evenodd"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Form */}
            <div
              id="contact"
              className="flex flex-col justify-center items-center row-start-1 md:col-start-2">
              <div className="w-full md:w-3/4">
                <div className="pb-4 md:pb-12">
                  <h1 className="font-title text-2xl md:text-3xl text-center md:text-left">
                    Contact Us
                  </h1>
                </div>
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer>
        <div className="container mx-auto h-full pt-20 px-4">
          <div className="grid grid-row-3 lg:grid-cols-3 gap-4 h-full items-end">
            <div className="row-end-3 lg:row-end-1 mb-8">
              <div>
                <h6 className="font-title text-sm md:text-base text-center lg:text-left pb-2">
                  JDR General Contracting
                </h6>
                <p className="text-sm text-center lg:text-left uppercase tracking-widest">
                  Custom Home Builds & Excavation services that are always "Just
                  Done Right".
                </p>
              </div>
            </div>
            <div className="row-end-1 lg:row-end-1 mb-8">
              <div className="flex justify-center">
                <Logo className="logo" />
              </div>
            </div>
            <div className="row-end-2 lg:row-end-1 mb-8">
              <ul className="flex flex-wrap lg:flex-nowrap lg:flex-col select-none">
                {/* <li className="font-title link w-1/2 text-center lg:text-right text-xl lg:w-full py-4 lg:py-2">
                  <Link to="#about">About</Link>
                </li> */}
                <li className="font-title link w-1/2 text-center lg:text-right text-xl lg:w-full py-4 lg:py-2">
                  <Link to="#specialties">Services</Link>
                </li>
                {/* <li className="font-title link w-1/2 text-center lg:text-right text-xl lg:w-full py-4 lg:py-2">
                  <Link to="#gallery">Gallery</Link>
                </li> */}
                <li className="font-title link w-1/2 text-center lg:text-right text-xl lg:w-full py-4 lg:py-2">
                  <Link to="#contact">Contact</Link>
                </li>
              </ul>
            </div>
          </div>

          {/* <!-- FOOTER BAR --> */}
          <div className="footer-bar py-6">
            <div className="flex flex-col md:flex-row md:justify-between items-center h-full">
              <p className="font-title text-xs sm:text-sm">
                &copy; {new Date().getFullYear()} JDR General Contracting, LLC
                CCB #183406 – Site by JSPN
              </p>
              <div className="hidden md:flex flex-row">
                <a href="https://www.instagram.com/just.done.right/"
                        target="_blank"
                        rel="noreferrer" className="px-4">
                  <svg
                    className="social-icon-footer"
                    xmlns="http://www.w3.org/2000/svg"
                    width="39.434"
                    height="39.434"
                    viewBox="0 0 39.434 39.434">
                    <path
                      id="Path_236"
                      data-name="Path 236"
                      d="M19.717,3.505a60.456,60.456,0,0,1,7.887.219,10.161,10.161,0,0,1,3.724.657,7.694,7.694,0,0,1,3.724,3.724,10.161,10.161,0,0,1,.657,3.724c0,1.972.219,2.629.219,7.887a60.456,60.456,0,0,1-.219,7.887,10.161,10.161,0,0,1-.657,3.724,7.694,7.694,0,0,1-3.724,3.724,10.161,10.161,0,0,1-3.724.657c-1.972,0-2.629.219-7.887.219a60.456,60.456,0,0,1-7.887-.219,10.161,10.161,0,0,1-3.724-.657,7.694,7.694,0,0,1-3.724-3.724A10.161,10.161,0,0,1,3.724,27.6c0-1.972-.219-2.629-.219-7.887a60.456,60.456,0,0,1,.219-7.887,10.161,10.161,0,0,1,.657-3.724A7.868,7.868,0,0,1,5.915,5.915,3.7,3.7,0,0,1,8.106,4.382a10.161,10.161,0,0,1,3.724-.657,60.456,60.456,0,0,1,7.887-.219m0-3.505a64.731,64.731,0,0,0-8.106.219,13.525,13.525,0,0,0-4.82.876A8.576,8.576,0,0,0,3.286,3.286,8.576,8.576,0,0,0,1.1,6.791a9.982,9.982,0,0,0-.876,4.82A64.731,64.731,0,0,0,0,19.717a64.731,64.731,0,0,0,.219,8.106,13.525,13.525,0,0,0,.876,4.82,8.576,8.576,0,0,0,2.191,3.505,8.576,8.576,0,0,0,3.505,2.191,13.525,13.525,0,0,0,4.82.876,64.732,64.732,0,0,0,8.106.219,64.731,64.731,0,0,0,8.106-.219,13.525,13.525,0,0,0,4.82-.876,9.191,9.191,0,0,0,5.7-5.7,13.525,13.525,0,0,0,.876-4.82c0-2.191.219-2.848.219-8.106a64.732,64.732,0,0,0-.219-8.106,13.525,13.525,0,0,0-.876-4.82,8.576,8.576,0,0,0-2.191-3.505A8.576,8.576,0,0,0,32.642,1.1a13.525,13.525,0,0,0-4.82-.876A64.731,64.731,0,0,0,19.717,0m0,9.639A9.915,9.915,0,0,0,9.639,19.717,10.078,10.078,0,1,0,19.717,9.639m0,16.65a6.455,6.455,0,0,1-6.572-6.572,6.455,6.455,0,0,1,6.572-6.572,6.455,6.455,0,0,1,6.572,6.572,6.455,6.455,0,0,1-6.572,6.572m10.516-19.5a2.41,2.41,0,1,0,2.41,2.41,2.431,2.431,0,0,0-2.41-2.41"
                      fill="#d0d0d0"
                      fillRule="evenodd"
                    />
                  </svg>
                </a>
                <a href="https://www.facebook.com/teamJDR" target="_blank"
                        rel="noreferrer" className="px-4">
                  <svg
                    className="social-icon-footer"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20.593"
                    height="39.434"
                    viewBox="0 0 20.593 39.434">
                    <path
                      id="Path_234"
                      data-name="Path 234"
                      d="M93.364,39.434V21.469H99.5l.876-7.01h-7.01V10.078c0-1.972.657-3.505,3.505-3.505h3.724V.219C99.717.219,97.526,0,95.116,0c-5.258,0-8.982,3.286-8.982,9.2v5.258H80v7.01h6.134V39.434Z"
                      transform="translate(-80)"
                      fill="#d0d0d0"
                      fillRule="evenodd"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Footer>
    </ContentWrap>
  );
}

export default IndexPage;

export const query = graphql`
  query {
    excavation: file(relativePath: { eq: "excavation.jpg" }) {
      name
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    customHome: file(relativePath: { eq: "home-build.jpg" }) {
      name
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

  }
`;
